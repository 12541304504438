.login-wrapper {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FF0000;
    padding-top: 15px;
    padding-bottom: 25px;
    color: white;
    width: 700px;
    left      : 50%;
    top       : 50%;
    position  : absolute;
    transform : translate(-50%, -60%);
}

.changePwd-wrapper {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FF0000;
    padding-top: 15px;
    padding-bottom: 25px;
    color: white;
    width: 700px;
    left      : 50%;
    top       : 40%;
    position  : absolute;
    transform : translate(-50%, -60%);
}

.login-wrapper > h3 {
    text-align: left;
    left: -40%;
}

.login-form {
    width: 100%;
    align-items: center;
    padding-left: 120px;
}

.logo {
    height: 90px;
    margin-right: 18px;
  }

.logo-wrapper {
    margin-top: 125px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 22pt;
}

.login-field {
    width: 220px;
    margin-right: 10px;
}

.forgot-pwd-field {
    width: 500px;
    margin-right: 10px;
    margin-left: 10px;
}

.forgot-pwd-blurb {
    width: 600px;
    margin-bottom: 50px;
}

.forgot-pwd-form {
    background-color: lightgray;
    padding: 20px;
    color: #FF0000;
    border-radius: 2px;
}

.change-pwd-form {
    background-color: lightgray;
    padding: 20px;
    color: #FF0000;
    border-radius: 2px;
}