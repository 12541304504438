.assignDateList {
    position: absolute;
    height: 80%;
    width: 300px;
    top: 40px;
    right: 20px;
    box-shadow: 1px 1px 30px lightslategray;
}

.assignDateTile {
    background-color: lightskyblue;
    border-radius: 4px;
    border: solid 1px lightslategray;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 4px;
}