.runsGrid {
    margin-top: 5px;
    display: grid;
    row-gap: 12px;
    column-gap: 8px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: 250px;
  }

  .runTile {
    background-color: lightskyblue;
    padding: 10px;
    position: relative;
    color: white;
  }

  .runNumber {
    font-size: x-large;
  }

  .runNumber:hover {
    cursor: pointer;
  }

  #runNumber {
    width: 120px;
  }

  .docketHeader {
    font-weight: bold;
  }

  .docketRow {
    background-color: rgb(242, 246, 250);
  }

  .docketRowAlt {
    background-color: rgb(210, 224, 235);
  }

  .sameAddressDivider {
    border-top: 1px dashed rgb(75, 73, 73);
  }