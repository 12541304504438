.depotTile {
    background-color: lightskyblue;
    padding: 10px;
    position: relative;
}

.mdaDepotTile {
    background-color: violet;
    padding: 10px;
    position: relative;
}

.depotName {
    font-weight: 600;
}

.depotEditBtn {
    position: absolute;
    text-align: right;
    bottom: 10px;
    right: 10px;
}