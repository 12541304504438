.addUserContainer {
    padding-top: 5px;
    padding-bottom: 10px;
}

.seasonTitle {
    color: white;
    background-color: cadetblue;
    margin-top: -30px;
}

.depotContainer {
    margin-top: 4px;
}

.depotCheck {
    margin-left: 2px;
}