.addDate {
    background-color: white;
    border-radius: 4px;
    border: solid 1px tomato;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    text-align: center;
}

.addDatetBtn {
    background-color: tomato;
}