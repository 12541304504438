.truckTypeGrid {
    display: grid;
    row-gap: 12px;
    column-gap: 8px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-auto-rows: 250px;
  }
  
  .newTruckType {
    display: flex;
    justify-content: center;
    align-items: center;
  }