.configDateTile {
    background-color: lightskyblue;
    padding: 10px;
    position: relative;
}

.morningDateTile {
    background-color: mediumseagreen;
    padding: 10px;
    position: relative;
}

.afternoonDateTile {
    background-color: lightsalmon;
    padding: 10px;
    position: relative;
}

.deleteButton {
    position: absolute;
    right: 10px;
    bottom: 10px;
}