.addDepot {
    background-color: white;
    border-radius: 4px;
    border: solid 1px tomato;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.addDepotBtn {
    background-color: tomato;
}


.addMDADepotBtn {
    background-color: blueviolet;
}

.addMDADepotBtn:hover {
    background-color: darkorchid;
}