.taskId {
    font-size: x-small;
}

.taskHeader {
    font-weight: bold;
}

.taskList {
    font-size: smaller;
}