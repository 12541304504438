/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.grid-container {
  display: grid;
  row-gap: 16px;
  column-gap: 12px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-rows: 200px;
}

.formGroup {
  position: relative;
  padding: 3em 1em 1em;
  border: 1px solid #d8d8d8;
  margin: 1.5rem 0 0;
}

.groupTitle {
  position: absolute;
    left: 0;
    top: 0;
    padding: 1em;
    color: #aaa;
    font-size: smaller;
    font-weight: 500;
}

.seasonSelector {
  width: 18rem;
  display: flex;
  flex-direction: column;
  align-content: center;
}

.routerTitle {
  align-content: center;
}

.addNewSeasonBtn {
  position: relative;
  right: 1em;
  bottom: 1em;
}