.dateTile {
    height: 100px;
    margin-bottom: 4px;
}

.dateTile:hover {
    cursor: pointer;
}

.emptyDate {
    /* width: 20rem; */
    background-color: lightslategray;
}

.emptyDate:hover {
    cursor: default;
}

.morning {
    /* width: 20rem; */
    background-color: mediumseagreen;
    height: 50px;
    margin-bottom: 0px;
}

.afternoon {
    /* width: 20rem; */
    background-color: lightsalmon;
    height: 50px;
}

.fullDate {
    /* width: 20rem; */
    background-color: lightskyblue;
    height: 100px;
}

.calGrid {
    background-color: lightgrey;
    font-size: 12px;
}

.calHeader {
    font-weight: bold;
}

.detailsBtn {
    position: absolute;
    text-align: right;
    bottom: 10px;
    right: 10px;
}

.activeTile {
    filter: brightness(80%);
}