.resubmit-card {
    width: 18rem;
}

.importHeader {
    font-weight: bold;
}

.missingGeocode {
    color: red;
}

.missingGeocode:hover {
    cursor: pointer;
}

.btnGroup {
    padding-bottom: 5px;
}

.btnGroup>button {
    margin-right: 3px;
}