.labelText {
    font-size: 9px;
}

.marker {
    width: 64px;
    height: 64px;
    transform: translate(-50%, -50%);
}

.infoWindow {
    position:relative;
    bottom: 150px;
    left: -45px;
    width: 400px;
    background-color: white;
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
    padding: 10px;
    font-size: 11pt;
    z-index: 10;
}

.infoWindowTitle {
    font-size: 12pt;
    font-weight: bold;
}

.infoCloseBtn {
    position: relative;
    float: right;
    top: -25px;
    right: -5px;
}

.newRunSelect {
    z-index: 20;
}