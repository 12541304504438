.truckTypeTile {
    background-color: limegreen;
    padding: 10px;
    position: relative;
    border-radius: 4px;
}

.truckTypeDesc {
    font-weight: 500;
}

.ttPerTruck {
    /* border: 1px solid;
    border-color: darkgreen;
    border-radius: 4px; */
    background-color: lightgreen;
    margin-top: 5px;
}

.ttPerTruck > div {
    margin-left: 1em;
}

.topButtons {
    position: absolute;
    right: 8px;
    top: 15px;    
}

.topButtons > button {
    margin: 1px;
}

.bottomBtns {
    position: absolute;
    right: 8px;
    bottom: 8px;
}

.bottomBtns > button {
    margin: 1px;
}

.truckForm {
    font-size: 14px;
}

.ttNumInput {
    width: 80px;
}